.panel {
  display: inline-block;
  position: absolute;
  background-color: white;
  
  opacity: 1;
  z-index: 1;
}

.panel h1 {
  text-align: center;
}

.panel .content {
  position: absolute;
  left: inherit;
  right: inherit;
  width: 70vw;
  /* top: inherit;
  bottom: inherit; */
  /* transform: translate(-50%, -50%); */
}

.panel.big {
  width: 80vw;
  height: 80vh;
  overflow-x: hidden;
  overflow-y: auto;
  border: thick double darkgray;
  text-align: center;
  border-radius: 0;
}

.panel.big .content {
  padding: 2rem;
}

.panel.small {
  width: 6rem;
  height: 6rem;
  overflow: hidden;
}

.panel .control.button {
  position: absolute;
  margin: 0;

  margin: 0.1rem;
  padding: 1rem;

  z-index: 2;
}

.panel .control.button img {
  height: 3rem;
  width: 3rem;

  margin: 0;
  padding: 0;
}